import { acceptHMRUpdate, defineStore, storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { useSettingsStore } from "store/modules/settingStore.js";
import useHelpers from "composables/useHelpers.js";
import { shouldFetch } from "common/fetchHelpers.js";
import axios from "axios";

export const useOverviewStore = defineStore("overview", () => {
  // Refs
  const group_meetings = ref([]);
  const group_meeting_filters = ref([]);

  // States
  const isFetching = ref({
    group_meetings: false,
    group_meeting_filters: false,
  });

  const hasFetched = ref({
    group_meetings: false,
    group_meeting_filters: false,
  });

  // Methods
  const fetchGroupMeetings = async ({
    forceFetch = false,
    meetingIds = [],
  } = {}) => {
    if (!shouldFetch(hasFetched.value["group_meetings"]) && !forceFetch) {
      return group_meetings.value || [];
    }

    const { schoolId } = useHelpers();

    isFetching.value["group_meetings"] = true;

    try {
      const { data: fetchedData } = await axios.get(
        `/api/school/${schoolId}/group-meetings/overview`,
        {
          params: {
            "group_meetings[]": meetingIds,
          },
        },
      );

      hasFetched.value["group_meetings"] = new Date();

      group_meetings.value = fetchedData.data;

      return group_meetings.value;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);

        return Promise.reject(error.response);
      }
    } finally {
      isFetching.value["group_meetings"] = false;
    }
  };

  const fetchGroupMeetingFilters = async ({ forceFetch = false } = {}) => {
    if (
      !shouldFetch(hasFetched.value["group_meeting_filters"]) &&
      !forceFetch
    ) {
      return group_meeting_filters.value || [];
    }

    const { schoolId } = useHelpers();

    isFetching.value["group_meeting_filters"] = true;

    try {
      const { data: fetchedData } = await axios.get(
        `/api/school/${schoolId}/group-meetings/overview/filters`,
      );

      hasFetched.value["group_meeting_filters"] = new Date();

      group_meeting_filters.value = fetchedData.data;

      return group_meeting_filters.value;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);

        return Promise.reject(error.response);
      }
    } finally {
      isFetching.value["group_meeting_filters"] = false;
    }
  };

  return {
    group_meetings,
    group_meeting_filters,
    fetchGroupMeetings,
    fetchGroupMeetingFilters,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useOverviewStore, import.meta.hot));
}
