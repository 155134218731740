<template>
  <q-dialog ref="dialogRef" :persistent @hide="onDialogHide">
    <q-card class="q-dialog-plugin !tw-shadow-xl">
      <q-card-section class="tw-flex tw-items-center tw-gap-4">
        <q-icon :class="iconType.class" :name="iconType.icon" />
        <h3 class="tw-text-xl tw-font-semibold">{{ title }}</h3>
      </q-card-section>

      <q-card-section>
        <p class="tw-m-0 tw-text-base">{{ message }}</p>
      </q-card-section>

      <q-card-section>
        <slot />
      </q-card-section>

      <q-card-actions align="right">
        <c-button
          v-if="cancel"
          color="transparent"
          text-color="gray"
          label="Annuleren"
          @click="onDialogCancel"
        />

        <c-button
          :color="iconType.buttonStyle"
          :label="primaryLabel"
          @click="onOKClick"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { useDialogPluginComponent } from "quasar";
import { computed } from "vue";

const props = defineProps({
  title: {
    type: String,
    required: true,
    default: () => "",
  },
  message: {
    type: String,
    required: true,
    default: () => "",
  },
  cancel: {
    type: Boolean,
    default: () => true,
  },
  persistent: {
    type: Boolean,
    default: () => false,
  },
  primaryLabel: {
    type: String,
    default: () => "",
  },
  type: {
    type: String,
    default: "warning",
  },
  returnData: {
    type: String,
    default: "",
  },
});

defineEmits([...useDialogPluginComponent.emits]);

const iconType = computed(() => {
  switch (props.type) {
    case "warning":
      return {
        icon: "fas fa-exclamation-triangle",
        class: "tw-text-warning",
        buttonStyle: "negative",
      };
    case "error":
      return {
        icon: "fas fa-exclamation-circle",
        color: "tw-text-negative",
        buttonStyle: "negative",
      };
    case "success":
      return {
        icon: "fas fa-check-circle",
        color: "positive",
        buttonStyle: "primary",
      };
    case "info":
      return {
        icon: "fas fa-info-circle",
        color: "tw-text-primary",
        buttonStyle: "primary",
      };
    default:
      return {
        icon: "fas fa-exclamation-triangle",
        color: "warning",
        buttonStyle: "negative",
      };
  }
});

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent();

function onOKClick() {
  onDialogOK(props.returnData);
}
</script>
