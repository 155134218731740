<template>
  <MeetingModule>
    <c-input
      label="Naam"
      :placeholder="namePlaceholder"
      v-model="model.name"
      lazy-rules
      required
      :rules="[required, min(4)]"
      autofocus
      @update:model-value="modelUpdate"
      hide-bottom-space
    />
  </MeetingModule>
</template>

<script setup>
import { computed, onMounted } from "vue";
import MeetingModule from "components/meeting/modules/MeetingModule.vue";
import { required, min } from "common/validationRules.js";
import { getCurrentSchoolYear } from "composables/useHelpers";

// Define the model
const model = defineModel({
  required: true,
  type: Object,
});

const namePlaceholder = computed(() => {
  return `Bijv: ${getCurrentSchoolYear()} Bespreking onderbouw`;
});

const isStepCompleted = defineModel("isStepCompleted");

// Update model value
const modelUpdate = (value) => {
  isStepCompleted.value = value && value.length > 3;
};

onMounted(() => {
  // Set a default value for the model when it's empty
  if (model.value?.name == null) {
    model.value.name = "";
  }

  // Check the model onMounted
  modelUpdate(model.value.name);
});
</script>
