<template>
  <ol>
    <li>
      <span class="tw-font-bold">Datum A (verplicht)</span>

      <label class="tw-mb-2 tw-block tw-tracking-wide tw-text-gray-700">
        Wanneer wordt de bespreking open gezet?
      </label>

      <div class="tw-grid tw-grid-cols-2 tw-gap-4 lg:tw-w-1/2">
        <div class="tw-col-span-1">
          <c-input-date-select
            class="tw-flex-1"
            label="Datum"
            v-model="model.timeframe_settings.opens_at_date"
            :max-date="dateRangeOptions.a.date.max"
          />
        </div>

        <div class="tw-col-span-1">
          <c-input-time-select
            v-model="model.timeframe_settings.opens_at_time"
            class="tw-flex-1"
            label="Tijdstip"
            :disable="
              model.timeframe_settings.opens_at_date === '' ||
              model.timeframe_settings.opens_at_date == null
            "
            required
          />
        </div>
      </div>

      <div>
        <label
          class="tw-mb-2 tw-mt-2 tw-block tw-tracking-wide tw-text-gray-700"
        >
          {{ sendEmailAutomaticallyLabel }}
        </label>

        <div class="tw-grid tw-gap-4 lg:tw-w-1/2">
          <div>
            <c-radio-group
              :options="[
                { value: true, label: 'Ja' },
                { value: false, label: 'Nee' },
              ]"
              v-model="model.timeframe_settings.auto_invite_when_meeting_opens"
              inline
            />
          </div>
        </div>
      </div>
    </li>

    <li>
      <span class="tw-font-bold">Datum B (verplicht)</span>
      <label class="tw-mb-2 tw-block tw-tracking-wide tw-text-gray-700">
        Wanneer wordt de bespreking definitief gesloten, en zijn aanpassingen
        niet meer mogelijk?<br />
      </label>

      <div class="tw-grid tw-grid-cols-2 tw-gap-4 lg:tw-w-1/2">
        <div class="tw-col-span-1">
          <c-input-date-select
            class="tw-flex-1"
            label="Datum"
            placeholder="dd-mm-jjjj"
            :disable="
              model.timeframe_settings.opens_at_date === null ||
              model.timeframe_settings.opens_at_date === ''
            "
            v-model="model.timeframe_settings.closed_no_modify_at_date"
            :min-date="dateRangeOptions.b.date.min"
            :change-to-min-date="false"
          />
        </div>

        <div class="tw-col-span-1">
          <c-input-time-select
            v-model="model.timeframe_settings.closed_no_modify_at_time"
            class="tw-flex-1"
            label="Tijdstip"
            :disable="
              model.timeframe_settings.closed_no_modify_at_date === '' ||
              model.timeframe_settings.closed_no_modify_at_date == null
            "
            :compare-time="dateRangeOptions.b.time.compare"
            :min-time="dateRangeOptions.b.time.min"
            required
          />
        </div>
      </div>

      <label class="tw-mb-2 tw-mt-2 tw-block tw-tracking-wide tw-text-gray-700">
        Automatisch een reminder sturen naar docenten die nog niet alles hebben
        ingevuld, 48 uur vóór deze sluiting?
      </label>

      <div class="tw-grid tw-grid-cols-2 tw-gap-4 lg:tw-w-1/2">
        <div class="tw-col-span-1">
          <c-radio
            :val="true"
            label="Ja"
            v-model="
              model.timeframe_settings.send_reminder_before_closing_no_modify
            "
          />
        </div>
        <div class="tw-col-span-1">
          <c-radio
            :val="false"
            label="Nee"
            v-model="
              model.timeframe_settings.send_reminder_before_closing_no_modify
            "
          />
        </div>
      </div>
    </li>

    <li>
      <span class="tw-font-bold">Datum C (optioneel)</span>

      <label class="tw-mb-2 tw-block tw-tracking-wide tw-text-gray-700">
        Wanneer staan de vergaderingen gepland?
      </label>

      <DateFromTo
        :min-date="dateRangeOptions.c.date.min"
        :min-time="dateRangeOptions.c.time.min"
        v-model="model.timeframe_settings.meeting_dates"
        :multiple="true"
        :change-to-min-date="false"
        :disable="
          model.timeframe_settings.closed_no_modify_at_date === null ||
          model.timeframe_settings.closed_no_modify_at_date === ''
        "
      />
    </li>

    <li>
      <span class="tw-font-bold">Datum D (verplicht)</span>
      <label class="tw-mb-2 tw-block tw-tracking-wide tw-text-gray-700">
        Wanneer is de bespreking afgerond?<br />
      </label>

      <div class="tw-grid tw-grid-cols-2 tw-gap-4 lg:tw-w-1/2">
        <div class="tw-col-span-1">
          <c-input-date-select
            class="tw-flex-1"
            label="Datum"
            placeholder="dd-mm-jjjj"
            v-model="model.timeframe_settings.archived_at_date"
            :disable="
              model.timeframe_settings.closed_no_modify_at_date === null ||
              model.timeframe_settings.closed_no_modify_at_date === ''
            "
            :min-date="dateRangeOptions.d.date.min"
            :change-to-min-date="false"
          />
        </div>

        <div class="tw-col-span-1">
          <c-input-time-select
            v-model="model.timeframe_settings.archived_at_time"
            class="tw-flex-1"
            label="Tijdstip"
            :disable="
              model.timeframe_settings.archived_at_date === '' ||
              model.timeframe_settings.archived_at_date == null
            "
            :compare-time="dateRangeOptions.d.time.compare"
            :min-time="dateRangeOptions.d.time.min"
            required
          />
        </div>
      </div>
    </li>
  </ol>

  <Alert v-if="showStatusUpdate" status="warning">
    <span class="tw-font-bold">Let op:</span>&nbsp;bij het opslaan van deze
    bespreking wordt de status op
    <span class="tw-font-bold tw-lowercase">&nbsp;{{ dateStatus }}&nbsp;</span>
    gezet.
  </Alert>
</template>

<script setup>
import { computed, ref, watch, onBeforeMount, reactive, onUpdated } from "vue";
import Alert from "@/components/global/Alert.vue";
import moment from "moment";
import { isArray } from "chart.js/helpers";
import useHelpers from "@/composables/useHelpers";
import { DEFAULTSTARTTIME } from "common/timeOptions";
import DateFromTo from "components/date/DateFromTo.vue";
import { getDateString } from "common/dateHelpers";
import { fromDutchToIntlDate } from "common/dateHelpers";

// Define the model
const model = defineModel({
  required: true,
  type: Object,
});

const { mapStatus } = useHelpers();
const dateStatus = ref(null);
const originalStatus = ref(null);

const props = defineProps({
  edit: {
    type: Boolean,
    default: false,
  },
  status: {
    type: String,
    default: "",
  },
});

const timeframeSettings = ref({
  opens_at_date: null,
  opens_at_time: null,
  closed_no_modify_at_date: null,
  closed_no_modify_at_time: null,
  archived_at_date: null,
  archived_at_time: null,
  meeting_dates: [],
  send_reminder_before_closing_no_modify: false,
  auto_invite_when_meeting_opens: false,
});

// Date refs
const dateTypes = ref(["a", "b", "b", "c", "d", "e"]);
const availableDateTimeOptions = reactive({});

onBeforeMount(() => {
  originalStatus.value = model.value.status;
  model.value.timeframe_settings = Object.assign(
    timeframeSettings.value,
    model.value.timeframe_settings,
  );

  // Add the available date types to the object
  dateTypes.value.map((key) => {
    availableDateTimeOptions[key] = { date: {}, time: {} };
  });

  checkDates();
});

onUpdated(() => {
  model.value.timeframe_settings = Object.assign(
    timeframeSettings.value,
    model.value.timeframe_settings,
  );
});

// Return an object with the min and max date per date type
const dateRangeOptions = computed(() => {
  const {
    closed_no_modify_at_date,
    closed_no_modify_at_time,
    archived_at_date,
    meeting_dates,
  } = timeframeSettings.value;
  let { opens_at_date, opens_at_time } = timeframeSettings.value;

  opens_at_date = opens_at_date || getDateString();
  opens_at_time = opens_at_time || DEFAULTSTARTTIME;

  // This can be optimized as we reuse a lot of logic
  Object.keys(availableDateTimeOptions).forEach((key) => {
    switch (key) {
      case "a":
        availableDateTimeOptions[key]["date"]["max"] = getDateString(
          Date.now() + 48384e5,
        );
        break;
      case "b":
        availableDateTimeOptions[key]["date"]["min"] = opens_at_date;
        availableDateTimeOptions[key]["time"]["min"] = opens_at_time;
        availableDateTimeOptions[key]["time"]["compare"] =
          opens_at_date === closed_no_modify_at_date;
        break;
      case "c":
        if (closed_no_modify_at_date) {
          availableDateTimeOptions[key]["date"]["min"] =
            closed_no_modify_at_date;
          availableDateTimeOptions[key]["time"]["min"] =
            closed_no_modify_at_time;
        }
        break;
      case "d":
        if (isArrayAndHasValue(meeting_dates, "end_date")) {
          setMinDateTime(
            meeting_dates,
            availableDateTimeOptions[key],
            archived_at_date,
          );
        } else {
          // meetings is not filled, check against the closed no modify at date and time
          availableDateTimeOptions[key]["date"]["min"] =
            closed_no_modify_at_date;
          availableDateTimeOptions[key]["time"]["min"] =
            closed_no_modify_at_time;
          availableDateTimeOptions[key]["time"]["compare"] =
            availableDateTimeOptions[key]["date"]["min"] === archived_at_date;
        }
        break;
    }
  });

  return availableDateTimeOptions;
});

// Hide or show the status update Alert component.
const showStatusUpdate = computed(() => {
  return (
    dateStatus.value !== null &&
    mapStatus(originalStatus.value) !== dateStatus.value
  );
});

const setMinDateTime = (
  valueToCheck,
  availableDateTimeOptions,
  compareToValue = false,
) => {
  // Set min date and time
  availableDateTimeOptions["date"]["min"] = isArrayAndHasValue(
    valueToCheck,
    "end_date",
  );

  // Set correct min time
  availableDateTimeOptions["time"]["min"] = isArrayAndHasValue(
    valueToCheck,
    "end_time",
  );

  // Check if the the dates are the same so the time should compare
  // Set false so the min-time is not being applied when the current selected date is not the min date
  if (compareToValue !== false) {
    availableDateTimeOptions["time"]["compare"] =
      availableDateTimeOptions["date"]["min"] === compareToValue;
  } else {
    availableDateTimeOptions["time"]["compare"] = false;
  }
};

// Check if the value is an actual array,
// has a length of more than 0 and has an end_date
// Return the value or false
const isArrayAndHasValue = (value, key) => {
  return value &&
    isArray(value) &&
    value.length > 0 &&
    !!value[value.length - 1]?.[key]
    ? value[value.length - 1][key]
    : false;
};

const timeIsSmallerThanNow = ref({});

watch(
  timeframeSettings.value,
  () => {
    checkDates();
  },
  {
    deep: true,
  },
);

// Timeframs is not reactive so we need to assign it to a reactive object
watch(model.value.timeframe_settings, () => {
  Object.assign(timeframeSettings.value, model.value.timeframe_settings);
});

const sendEmailAutomaticallyLabel = computed(() => {
  let { opens_at_date, opens_at_time } = timeframeSettings.value;

  if (!opens_at_date) {
    // When the start date is not defined yet
    return "Wil je automatisch de uitnodigingen versturen bij de start van deze bespreking?";
  }

  const now = new Date();
  const meetingStartTime = fromDutchToIntlDate(opens_at_date, opens_at_time);

  if (now > meetingStartTime) {
    return "Wil je automatisch de uitnodigingen versturen op dit moment?";
  }

  return "Wil je automatisch de uitnodigingen versturen bij de start van deze bespreking?";
});

const createMoment = (date, time) =>
  moment(`${date} ${time}`, "DD-MM-YYYY HH:mm");

const checkDates = () => {
  const opensAtDate = createMoment(
    timeframeSettings.value.opens_at_date,
    timeframeSettings.value.opens_at_time ?? "00:00",
  );
  const closedNoModifyDate = createMoment(
    timeframeSettings.value.closed_no_modify_at_date,
    timeframeSettings.value.closed_no_modify_at_time ?? "00:00",
  );
  const archivedDate = createMoment(
    timeframeSettings.value.archived_at_date,
    timeframeSettings.value.archived_at_time ?? "00:00",
  );

  timeIsSmallerThanNow.value = {
    opensAtDate: opensAtDate < moment(),
    closedNoModifyDate: closedNoModifyDate < moment(),
    archivedDate: archivedDate < moment(),
  };

  if (timeIsSmallerThanNow.value.archivedDate) {
    dateStatus.value = "Afgerond, aan de slag!";
    return;
  }

  if (timeIsSmallerThanNow.value.closedNoModifyDate) {
    dateStatus.value = "Gesloten, aanpassingen niet meer mogelijk";
    return;
  }

  if (timeIsSmallerThanNow.value.opensAtDate) {
    dateStatus.value = "Open";
    return;
  }

  dateStatus.value = "Concept";
};
</script>

<style lang="scss" scoped>
li {
  @apply tw-mb-4 tw-pb-4;
}
</style>
