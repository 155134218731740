<template>
  <q-tooltip
    transition-show="jump-up"
    transition-hide="jump-down"
    anchor="top middle"
    self="bottom middle"
    class="bg-primary"
    ><slot
  /></q-tooltip>
</template>
